import dictionaries from '@resider/dictionaries';

const districtsAndSubDistrictsKeys = Object.keys({
	...dictionaries.locations.districts['warszawa'],
	...dictionaries.locations.subdistricts['warszawa']
});

/** @type {import('@sveltejs/kit').ParamMatcher} */
export function match(param) {
	const inputDistrictsOrSubdistricts = `${param}`.split(',').map((s) => s.trim());

	const areAllValid = inputDistrictsOrSubdistricts.every((districtOrSubdistrict) =>
		districtsAndSubDistrictsKeys.includes(districtOrSubdistrict)
	);

	return areAllValid;
}