import dictionaries from '@resider/dictionaries';

const subdistrictsKeys = Object.keys(dictionaries.locations.subdistricts['warszawa']);

/** @type {import('@sveltejs/kit').ParamMatcher} */
export function match(param) {
	const inputDistrictsOrSubdistricts = `${param}`.split(',').map((s) => s.trim());

	const areAllValid = inputDistrictsOrSubdistricts.every((districtOrSubdistrict) =>
		subdistrictsKeys.includes(districtOrSubdistrict)
	);

	return areAllValid;
}