import { stringToKebabCase } from '@resider/utils';
import dictionaries from '@resider/dictionaries';


/**
 * @type {string[]}
 */
const cityNames = [];

for (const region in dictionaries.locations.cities) {
  for (const city in dictionaries.locations.cities[region]) {
    const cityName = dictionaries.locations.cities[region][city];
    
    cityNames.push(cityName);
  }
}

export function match(param) {
  const cityRegex = new RegExp(cityNames.map(stringToKebabCase).join('|'), 'i');
  return cityRegex.test(`${param}`);
}
