import { match as account } from "../../../src/params/account.js";
import { match as chat } from "../../../src/params/chat.js";
import { match as city } from "../../../src/params/city.js";
import { match as confirm_account } from "../../../src/params/confirm_account.js";
import { match as contact } from "../../../src/params/contact.js";
import { match as district } from "../../../src/params/district.js";
import { match as explore } from "../../../src/params/explore.js";
import { match as history } from "../../../src/params/history.js";
import { match as lang } from "../../../src/params/lang.js";
import { match as liked } from "../../../src/params/liked.js";
import { match as marketing_consents } from "../../../src/params/marketing_consents.js";
import { match as my_alerts } from "../../../src/params/my_alerts.js";
import { match as new_password } from "../../../src/params/new_password.js";
import { match as offer } from "../../../src/params/offer.js";
import { match as offer_id } from "../../../src/params/offer_id.js";
import { match as offer_type } from "../../../src/params/offer_type.js";
import { match as page } from "../../../src/params/page.js";
import { match as reset_password } from "../../../src/params/reset_password.js";
import { match as search_history } from "../../../src/params/search_history.js";
import { match as settings } from "../../../src/params/settings.js";
import { match as sign_in } from "../../../src/params/sign_in.js";
import { match as sign_out } from "../../../src/params/sign_out.js";
import { match as sign_up } from "../../../src/params/sign_up.js";
import { match as subdistrict } from "../../../src/params/subdistrict.js";
import { match as viewed_offers } from "../../../src/params/viewed_offers.js";

export const matchers = { account, chat, city, confirm_account, contact, district, explore, history, lang, liked, marketing_consents, my_alerts, new_password, offer, offer_id, offer_type, page, reset_password, search_history, settings, sign_in, sign_out, sign_up, subdistrict, viewed_offers };