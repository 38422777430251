export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28')
];

export const server_loads = [0];

export const dictionary = {
		"/[lang=lang]/(marketing_pages)": [11,[3]],
		"/[lang=lang]/(marketing_pages)/blog/[[page=page]]": [~14,[3]],
		"/[lang=lang]/(marketing_pages)/blog/[[page=page]]/[post]": [~15,[3]],
		"/[lang=lang]/[marketing_consents=marketing_consents]": [26],
		"/[lang=lang]/[liked=liked]": [~25],
		"/[lang=lang]/[explore=explore]": [~23],
		"/[lang=lang]/[account=account]": [~16,[4]],
		"/[lang=lang]/(marketing_pages)/[slug=contact]": [12,[3]],
		"/[lang=lang]/(auth)/[sign_up=sign_up]": [10,[2]],
		"/[lang=lang]/(auth)/[sign_out=sign_out]": [~9,[2]],
		"/[lang=lang]/(auth)/[sign_in=sign_in]": [8,[2]],
		"/[lang=lang]/(auth)/[reset_password=reset_password]": [7,[2]],
		"/[lang=lang]/(auth)/[confirm_account=confirm_account]": [6,[2]],
		"/[lang=lang]/[offer=offer]/[[offer_type=offer_type]]/[[city=city]]/[[district=district]]/[[subdistrict=subdistrict]]/[offer_id=offer_id]": [~28],
		"/[lang=lang]/[account=account]/(subpages)/[viewed_offers=viewed_offers]": [~21,[4,5]],
		"/[lang=lang]/[account=account]/(subpages)/[settings=settings]": [20,[4,5]],
		"/[lang=lang]/[account=account]/(subpages)/[search_history=search_history]": [~19,[4,5]],
		"/[lang=lang]/[account=account]/(subpages)/[new_password=new_password]": [~18,[4,5]],
		"/[lang=lang]/[account=account]/(subpages)/[my_alerts=my_alerts]": [~17,[4,5]],
		"/[lang=lang]/[explore=explore]/[offer_type=offer_type]/[[city=city]]/[[district=district]]": [~24],
		"/[lang=lang]/[offer=offer]/[[offer_type=offer_type]]/[[city=city]]/[[district=district]]/[[subdistrict=subdistrict]]": [~27],
		"/[lang=lang]/[chat=chat]/[[chat_id]]": [~22],
		"/[lang=lang]/(marketing_pages)/[slug]": [13,[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';